import React from 'react';
import { Link } from "react-router-dom";
import { useState } from "react";

const Courses = () => {
  var campaign = [];
  //  const [slides, changeSlide] = useState('campaigns');

  const handleClick = (text) => {
    //    changeSlide(text);
  };

  return <div className="stage">
    Hello
  </div>;
};

export default Courses;