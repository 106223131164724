import React, { useRef, useEffect } from 'react';
import Side from "../side/Side";
import "./VideoP.css";
//import datafrom "data/data.json";
const VideoP = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this value as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play();
        } else {
          entry.target.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (<div className="videopane">
  <Side></Side>
  {src}
   <video ref={videoRef} src={src} controls={true} />
</div>
   
  );
};

export default VideoP;
