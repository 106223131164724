import React, { useState } from 'react';
import VideoUploader from '../components/upload/VideoUploader';

const Create = ({ setVideos }) => {
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const handleVideoUpload = (video) => {
    setUploadedVideo(video);
  };

  const handleMoveToSetVideos = () => {
    if (uploadedVideo) {
      setVideos((prevVideos) => [...prevVideos, uploadedVideo]);
      alert("uploaded");
    }
  };
  const removeSelectedVideo = () => {
    setSelectedVideo(null);
    alert("dropped");
  };

  return (
    <div className="stage" id="create">
      <div>
        <VideoUploader onVideoUpload={handleVideoUpload} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
      </div>
      <div>
        {uploadedVideo && (
          <div class="controls">
            <button onClick={handleMoveToSetVideos}>Upload</button>
            <button onClick={removeSelectedVideo}>Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Create;
