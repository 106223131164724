import { useState } from "react";
import Vr from "../components/vr/Vr";
import React from 'react';
import VideoP from '../components/video/VideoP';



const Main = ({videos}) => {
  var campaign = [];
 
  // const [slides, changeSlide] = useState('campaigns');

  const handleClick = (text) => {
    //    changeSlide(text);
  };

  return <div className="stage">
    <div>
      {videos.map((videoSrc, index) => (
        <VideoP key={index} src={videoSrc} />
      ))}
      <Vr></Vr>
    </div>
    

  </div>;
};

export default Main;