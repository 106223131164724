import React from 'react';
import { Link } from "react-router-dom";
import { useState } from "react";


import Side from "../side/Side";


const Vr = () => {
  var campaign = [];
  // const [slides, changeSlide] = useState('campaigns');

  const handleClick = (text) => {
    //    changeSlide(text);
  };

  return <div className="videopane">
    <Side></Side>

    <iframe width='853' height='480' src='https://my.matterport.com/show/?m=qRGSnp9PCSf&play=1' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>


  </div>
    ;
};

export default Vr;