import React from 'react';
import { Link } from "react-router-dom";
import { useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineHeart } from "react-icons/ai";
import { FaComments } from "react-icons/fa";
import { RxDragHandleDots1 } from "react-icons/rx";


const Side = () => {
  var campaign = [];
  //  const [slides, changeSlide] = useState('campaigns');

  const handleClick = (text) => {
    //    changeSlide(text);
  };
  const scrollup = () => {
    this.anyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return <div className="sidepanel">


    <Link hef="#"><AiOutlineHeart></AiOutlineHeart></Link><br />
    <Link hef="#"><FaComments></FaComments></Link><br />
    <Link hef="#"><BiUserCircle></BiUserCircle></Link><br /><br />
    <Link hef="#"><RxDragHandleDots1></RxDragHandleDots1></Link><br />


  </div>;
};

export default Side;