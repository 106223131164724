import logo from './logo.svg';
import './App.css';
import {useState} from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { SiProgress, SiGooglemessages } from 'react-icons/si'
import { AiFillPlusCircle } from 'react-icons/ai'
import { FaBook } from 'react-icons/fa'
import { RiSettings4Fill } from 'react-icons/ri'

import Main from './pages/Main'
import Messenger from './pages/Messenger'
import Create from './pages/Create'
import Courses from './pages/Courses'
import Settings from './pages/Settings'



function App() {

  const [videos,setVideos] = useState([
    'https://s3.ap-southeast-1.wasabisys.com/adept/pexels-pavel-danilyuk-5618514.mp4',
    'https://s3.ap-southeast-1.wasabisys.com/adept/pexels-pavel-danilyuk-5618514.mp4',
    'https://s3.ap-southeast-1.wasabisys.com/adept/pexels-pavel-danilyuk-5618514.mp4',
    // Add more video URLs as needed
  ]);

  return (
    <div className="App">

      <BrowserRouter>
        <div className="content">
          <Routes>
            <Route path="/" element={<Main videos={videos} />} />
            <Route path="messenger" element={<Messenger />} />
            <Route path="create" element={<Create videos={videos} setVideos={setVideos}/>} />
            <Route path="courses" element={<Courses />} />
            <Route path="settings" element={<Settings />} />

          </Routes>
        </div>
        <ul className="b_nav">

          <li>
            <Link to="/"><SiProgress></SiProgress></Link>
          </li>
          <li>
            <Link to="/messenger"><SiGooglemessages></SiGooglemessages></Link>
          </li>
          <li>
            <Link to="/Create"><AiFillPlusCircle></AiFillPlusCircle></Link>
          </li>
          <li>
            <Link to="/Courses"><FaBook></FaBook></Link>
          </li>
          <li>
            <Link to="/Settings"><RiSettings4Fill></RiSettings4Fill></Link>
          </li>
        </ul>

      </BrowserRouter>

    </div>
  );
}

export default App;