import React, { useState, useRef } from 'react';
import './VideoUploader.css';

const VideoUploader = ({ onVideoUpload, selectedVideo, setSelectedVideo }) => {
  const fileInputRef = useRef(null);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const videoUrl = reader.result;
      setSelectedVideo(videoUrl);
      onVideoUpload(videoUrl); // Pass the uploaded video URL to the parent component
    };

    reader.readAsDataURL(file);
  };

  const handlePaneClick = () => {
    fileInputRef.current.click(); // Trigger the file input click event
  };

  return (
    <div className="videopane" onClick={handlePaneClick}>
      <input
        type="file"
        accept="video/*"
        onChange={handleVideoUpload}
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the file input element
      />
      {selectedVideo && (
        <video controls>
          <source src={selectedVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoUploader;
